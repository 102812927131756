import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STEPS_DEFAULT_SORT_COLUMN, STEPS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
  clientData: [],
  loading: false,
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STEPS_LIST_ROWS_DEF,
    sortFieldProp: STEPS_DEFAULT_SORT_COLUMN,
    searchAlienNumber: '',
    searchModeOfCreation: "",
    searchBillingStatus: "",
    searchPrimaryContact: "",
    searchCurrentlyinUS: "",
    searchNationalities: [],
    searchLanguage: [],
    searchstartDate: '',
    searchendDate: '',
  },
  clientId: null,
  clietEditData: [],
  particularClientObj: {},
  clientAdded: false,
  activeTab: "clientDetails",
  childActiveTab: ""
};

export const getClientListAction = createAsyncThunk(
  "clientManagement/getClientListAction",
  async (value: any) => {
    const payload = {
      "page": String(value.currentPage),
      "size": String(value.rows),
      "sort": value.sortField,
      "sort_order": value.sortOrder,
      "search": value.searchKey ? value.searchKey : '', // primaryEmal111@yopmail.com
      "searchAlienNumber": value.searchAlienNumber ? value.searchAlienNumber : '',
      "searchModeOfCreation": value.searchModeOfCreation ? value.searchModeOfCreation : '',
      "searchBillingStatus": value.searchBillingStatus?.length > 0 ? value.searchBillingStatus.toString() : '',
      "searchPrimaryContact": value.searchPrimaryContact ? value.searchPrimaryContact : '',
      "searchCaseStatus": "",
      "searchCurrentlyinUS": value.searchCurrentlyinUS ? value.searchCurrentlyinUS : '',
      "searchNationalities": value.searchNationalities?.length > 0 ? value.searchNationalities.toString() : '', 
      "searchLanguage": value.searchLanguage?.length > 0 ? value.searchLanguage.toString() : '',
      "searchstartDate":value.searchstartDate ? value.searchstartDate : '',
      "searchendDate": value.searchendDate ? value.searchendDate : ''
  }
    const apiUrl = EndPoint.CLIENT_LIST_API
    try {
      const response = await axiosRequest.post(apiUrl, payload);
      return response;
    } catch (error: any) {
      return error;

    }
  }
);

export const addNewClientAction = createAsyncThunk(
  "clientManagement/addNewClientAction",
  async (value: any) => {
    const addData = {
      first_name: value.firstName,
      last_name: value.lastName,
      middle_name: value.middleName,
      salutation: value.salutation,
      dob: value.dob,
      gender: value.gender,
      city_of_birth: value.cityOfBirth,
      country_of_birth: value.contryOfBirth,
      status_on_i94: value.status_on_i94,
      alien_number: value.alien_number,
      client_type: value.clientType,
      category: value.category,
      status: value.status,
      preferred_name: value.preferedName,
      file_number: value.fileNumber,
      profile_pic: value.profileImage,
      file_location: value.fileLocation,
      tags: value.tags,
      main_contact: value.mainContact,
      language: value.language,
      currently_in_us: value.currently_in_us,
      consultation_date: value.consultation_date,
      passport_issue_date: value.passport_issue_date,
      passport_expiry_date: value.passport_expiry_date,
      status_valid_date: value.status_valid_date,
      passport_number: value.passport_number,
      passport_issuing_country: value.passport_issuing_country,

    }
    try {

      const response = await axiosRequest.post(`${EndPoint.CLIENT_ADD_API}`, addData);
      return response;

    } catch (error: any) {

      return error;

    }
  }
);

export const addClientInfoAction = createAsyncThunk(
  "clientManagement/addClientInfoAction",

  async (value: any) => {
    let natCountry = value.homeCountry === "" ? [] : value.homeCountry;
    const addData = {
      client_id: value.clientId,
      primary_email: value.primaryEmail,
      primary_phone: value.primaryPhone,
      alternate_email: value.alternateEmail,
      other_email: value.otherEmail,
      resident_aptfloordoor: value.aptCode,

      home_country: value.country,
      resident_address: value.aptName,
      resident_city: value.city,
      resident_county: value.county,
      resident_telephone1: value.telephone1,
      resident_telephone2: value.telephone2,
      resident_fax: value.fax,
      resident_mobilenumber: value.mobileNum,

      resident_zip_code: value.zipCode,
      resident_since: value.residantSince === "Invalid date" ? "" : value.residantSince,
      nationality_country: natCountry,
      primary_contact: value.primContact,
      resident_state: value.state,
      linked_in_id: value.linkedin,
      facebook_id: value.facebook,
      twitter_id: value.twitter,
      skype_id: value.skype,
      primary_phone_code: value.primaryPhCode,
      street_no: value.streetNo,
      street_name: value.streetName

    }
    try {

      const response = await axiosRequest.post(`${EndPoint.CLIENT_CONTACT_INFO_ADD}`, addData);
      return response;

    } catch (error: any) {

      return error;

    }
  }
);

export const getClientDetailAction = createAsyncThunk(
  "knowledgebaseModule/getClientDetailAction",
  async (value: any) => {

    const apiUrl = `${EndPoint.CLIENT_GET_DETAIL_API}/${value.getClientId}`



    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {
      return error;

    }
  }
);

export const updateClientAction = createAsyncThunk(
  "clientManagement/updateClientAction",
  async (value: any) => {
    console.log("vlue.id", value)
    const addData = {
      client_id: value.id,
      first_name: value.firstName,
      last_name: value.lastName,
      middle_name: value.middleName,
      salutation: value.salutation,
      dob: value.dob,
      gender: value.gender,
      city_of_birth: value.cityOfBirth,
      country_of_birth: value.contryOfBirth,
      status_on_i94: value.status_on_i94,
      alien_number: value.alien_number,
      client_type: value.clientType,
      category: value.category,
      status: value.status,
      preferred_name: value.preferedName,
      file_number_manual: value.fileNumber,
      profile_pic: value.profileImage,
      file_location: value.fileLocation,
      tags: value.tags,
      main_contact: value.mainContact,
      immigration_status: value.immigration_status,
      language: value.language,
      currently_in_us: value.currently_in_us,
      consultation_date: value.consultation_date,
      file_number_validation_required: value.file_number_validation_required,
      passport_issue_date: value.passport_issue_date,
      passport_expiry_date: value.passport_expiry_date,
      status_valid_date: value.status_valid_date,
      passport_number: value.passport_number,
      passport_issuing_country: value.passport_issuing_country,
    }
    try {

      const response = await axiosRequest.put(`${EndPoint.CLIENT_UPDATE_API}`, addData);
      return response;

    } catch (error: any) {

      return error;

    }
  }
);

export const updateClientInfoAction = createAsyncThunk(
  "clientManagement/updateClientInfoAction",
  async (value: any) => {

    const addData = {
      client_id: value.clientId,
      primary_email: value.primaryEmail,
      primary_phone: value.primaryPhone,
      alternate_email: value.alternateEmail,
      other_email: value.otherEmail,
      resident_aptfloordoor: value.aptCode,

      home_country: value.country,
      resident_address: value.aptName,
      resident_city: value.city,
      resident_county: value.county,
      resident_telephone1: value.telephone1,
      resident_telephone2: value.telephone2,
      resident_fax: value.fax,
      resident_mobilenumber: value.mobileNum,

      resident_zip_code: value.zipCode,
      resident_since: value.residantSince === "Invalid date" ? "" : value.residantSince,
      nationality_country: value.homeCountry,
      primary_contact: value.primContact,
      resident_state: value.state,
      linked_in_id: value.linkedin,
      facebook_id: value.facebook,
      twitter_id: value.twitter,
      skype_id: value.skype,
      primary_phone_code: value.primaryPhCode,
      street_no: value.streetNo,
      street_name: value.streetName

    }
    try {

      const response = await axiosRequest.put(`${EndPoint.CLIENT_CONTACT_INFO_UPDATE_API}`, addData);
      return response;

    } catch (error: any) {

      return error;

    }
  }
);
export const viewClientDetailAction = createAsyncThunk(
  "clientManagement/viewClientDetailAction",
  async (value: any) => {
    const apiUrl = `${EndPoint.CLIENT_VIEW_API}/${value.clientId}`
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const addClientRelativeAction = createAsyncThunk(
  "clientManagement/addClientRelativeAction",
  async (data: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.CLIENT_ADD_RELATIVES_API}`, data.values);
      return response;

    } catch (error: any) {

      return error;

    }
  }
);

export const getClientRelativeByClientId = createAsyncThunk(
  "clientManagement/getClientRelativeByClientId",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.CLIENT_RELATIVES_LIST_API}/${value.clientID}`,);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const deleteClientRelativeAction = createAsyncThunk(
  "clientManagement/deleteClientRelativeAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.CLIENT_RELATIVES_LIST_DELETE_API}/${value.relativeId}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const editClientRelativeAction = createAsyncThunk(
  "clientManagement/editClientRelativeAction",
  async (data: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.CLIENT_EDIT_RELATIVES_API}`, data.values);
      return response;

    } catch (error: any) {

      return error;

    }
  }
);

export const getClientRelativeById = createAsyncThunk(
  "clientManagement/getClientRelativeById",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.CLIENT_RELATIVES_BY_ID_API}/${value.selectedRelativeID}`,);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getAllUsersExcludingPrimaryContact = createAsyncThunk(
  "clientManagement/getAllUsersExcludingPrimaryContact",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_ALL_USERS_EXCLUDING_PRIMARY_CONTACT}?client_id=${value}`,);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const editClientPrimarContactAction = createAsyncThunk(
  "clientManagement/editClientPrimarContactAction",
  async (data: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.UPDATE_PRIMARY_CONTACT}`, data);
      return response;

    } catch (error: any) {

      return error;

    }
  }
);

export const getClientCasesListAction = createAsyncThunk(
  "clientManagement/getClientCasesListAction",
  async (value: any) => {
    const apiUrl = value.search === "" ?
      `${EndPoint.CLIENT_CASES_LIST_API}?client_id=${value.clientId}` :
      `${EndPoint.CLIENT_CASES_LIST_API}?client_id=${value.clientId}&search=${value.search}`;
    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {

      return error;
    }
  }
);

//EXPORT STAGES
export const exportClientAction = createAsyncThunk(
  "teamsModule/exportClientAction",
  async (value: any) => {
    const payload = {
      "sort": value.sortField,
      "sort_order": value.sortOrder,
      "search": value.searchKey ? value.searchKey : '', // primaryEmal111@yopmail.com
      "searchAlienNumber": value.searchAlienNumber ? value.searchAlienNumber : '',
      "searchModeOfCreation": value.searchModeOfCreation ? value.searchModeOfCreation : '',
      "searchBillingStatus": value.searchBillingStatus?.length > 0 ? value.searchBillingStatus.toString() : '',
      "searchPrimaryContact": value.searchPrimaryContact ? value.searchPrimaryContact : '',
      "searchCaseStatus": "",
      "searchCurrentlyinUS": value.searchCurrentlyinUS ? value.searchCurrentlyinUS : '',
      "searchNationalities": value.searchNationalities?.length > 0 ? value.searchNationalities.toString() : '', 
      "searchLanguage": value.searchLanguage?.length > 0 ? value.searchLanguage.toString() : '',
      "searchstartDate":value.searchstartDate ? value.searchstartDate : '',
      "searchendDate": value.searchendDate ? value.searchendDate : ''
    }
      const apiUrl = EndPoint.CLIENT_EXPORT
      try {
        const response = await axiosRequest.post(apiUrl, payload);
        return response;
      } catch (error: any) {
        return error;
      }
  }
);

/**
 * GETTING Billing information
 * arg: value object containing email
 * 
*/
export const getBillingStatusAction = createAsyncThunk(
  "caseManagement/getBillingStatusAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.CASE_BILLING_STATUS}?email=${value.email}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


const clientSlice = createSlice({
  name: 'Client',
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;

      state.listObj.searchAlienNumber = actions.payload.searchAlienNumber;
      state.listObj.searchModeOfCreation = actions.payload.searchModeOfCreation;
      state.listObj.searchBillingStatus = actions.payload.searchBillingStatus;
      state.listObj.searchPrimaryContact = actions.payload.searchPrimaryContact;
      state.listObj.searchCurrentlyinUS = actions.payload.searchCurrentlyinUS;
      state.listObj.searchNationalities = actions.payload.searchNationalities;
      state.listObj.searchLanguage = actions.payload.searchLanguage;
      state.listObj.searchstartDate = actions.payload.searchstartDate;
      state.listObj.searchendDate = actions.payload.searchendDate;
    },
    clearParticularClientObj(state, actions) {
      state.particularClientObj = {};
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
    activeTabStatus(state, actions) {
      state.activeTab = actions.payload.activeTab;
    },
    childAtiveTabStatus(state, actions) {
      state.childActiveTab = actions.payload.childActiveTab;
    },
    changeClientAddedStatus(state, actions) {
      state.clientAdded = actions.payload.clientAddStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientListAction.fulfilled, (state, action) => {

        let data = action.payload.data ? action.payload.data : []
        state.loading = false;
        state.clientData = data;
      })

      .addCase(getClientListAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(addNewClientAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewClientAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data.id : "";

        state.loading = false
        state.clientId = data
      })

      .addCase(addNewClientAction.rejected, (state, action) => {
        state.loading = false;
      })


      .addCase(getClientDetailAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientDetailAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data : "";

        state.loading = false
        state.clietEditData = data
      })

      .addCase(getClientDetailAction.rejected, (state, action) => {
        state.loading = false;
      })



      .addCase(updateClientAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateClientAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data.id : "";

        state.loading = false
        state.clientId = data
      })

      .addCase(updateClientAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(addClientInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addClientInfoAction.fulfilled, (state, action) => {


        state.loading = false

      })

      .addCase(addClientInfoAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateClientInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateClientInfoAction.fulfilled, (state, action) => {


        state.loading = false

      })

      .addCase(updateClientInfoAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(viewClientDetailAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewClientDetailAction.fulfilled, (state, action) => {
        state.loading = false;
        state.particularClientObj = action.payload.data;

      })
      .addCase(viewClientDetailAction.rejected, (state, action) => {
        state.loading = false;
        state.particularClientObj = {}
      })
      .addCase(addClientRelativeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addClientRelativeAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addClientRelativeAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editClientRelativeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editClientRelativeAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editClientRelativeAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteClientRelativeAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteClientRelativeAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteClientRelativeAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getClientRelativeById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientRelativeById.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getClientRelativeById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllUsersExcludingPrimaryContact.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersExcludingPrimaryContact.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllUsersExcludingPrimaryContact.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editClientPrimarContactAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editClientPrimarContactAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editClientPrimarContactAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getClientCasesListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientCasesListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getClientCasesListAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(exportClientAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportClientAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(exportClientAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBillingStatusAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingStatusAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getBillingStatusAction.rejected, (state, action) => {
        state.loading = false;
      })
  }
});


export const clientActions = clientSlice.actions;

export default clientSlice.reducer;