import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { axiosRequest } from '../common-api/axios';
import { STAGES_LIST_ROWS_DEF, LIST_CRR_PAGE, DEF_SORT_ORDER, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
const initialState = {
    questions: [],
    loading: false,
    questionsData: [],
    particularStageObj:{},
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN
    }
};

/**
 * Getting Question List
 *
 * @type {*}
 */
export const getQuestions = createAsyncThunk(
    "questionsModule/getQuestions",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.get(`${EndPoint.QUESTION_LIST}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 * Adding Questions 
 *
 * @type {*}
 */
export const addQuestions = createAsyncThunk(
    "questionsModule/addQuestions",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.values
        try {
            const response = await axiosRequest.post(`${EndPoint.QUESTION_ADD
            }`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


/**
 * Adding Questions 
 *
 * @type {*}
 */
export const updateQuestions = createAsyncThunk(
    "questionsModule/updateQuestions",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.values
        try {
            const response = await axiosRequest.put(`${EndPoint.QUESTION_UPDATE}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// Get Listing
export const getQuestionsListAction = createAsyncThunk(
    "questionsModule/getQuestionsListAction",
    async (value:any) => {
        const apiUrl = value.searchKey === "" ? 
        `${EndPoint.QUESTIONS_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}` : 
        `${EndPoint.QUESTIONS_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}`;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);


/**
 * Adding Questions 
 *
 * @type {*}
 */
export const getQuestionsByID = createAsyncThunk(
    "questionsModule/getQuestionsByID",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.QUESTION_BY_ID}/${value.questionID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// Delete 
export const deleteQuestionsAction = createAsyncThunk(
    "questionsModule/deleteQuestionsAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.QUESTIONS_DELETE_API}/${value.userId}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);

//EXPORT QUESTIONS
export const exportQuestionsAction = createAsyncThunk(
    "teamsModule/exportQuestionsAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ? `${EndPoint.QUESTIONS_EXPORT}?sort=${value.sortField}&sort_order=${value.sortOrder}` :
        `${EndPoint.QUESTIONS_EXPORT}?sort=${value.sortField}&search=${value.searchKey}&sort_order=${value.sortOrder}`
        try {

            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {

            return error;

        }
    }
);

const questionsSlice = createSlice({
    name: 'Question Management',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addQuestions.pending, (state) => {
                state.loading = true;
            })
            .addCase(addQuestions.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addQuestions.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getQuestionsByID.pending, (state) => {
                state.loading = true;
            })
            .addCase(getQuestionsByID.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getQuestionsByID.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateQuestions.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateQuestions.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateQuestions.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getQuestionsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getQuestionsListAction.fulfilled, (state, action) => {
                
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.questionsData = data;
            })
            .addCase(getQuestionsListAction.rejected, (state, action) => {
                state.loading = false;
             })
      
            .addCase(deleteQuestionsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteQuestionsAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteQuestionsAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(exportQuestionsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(exportQuestionsAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(exportQuestionsAction.rejected, (state, action) => {
               state.loading = false;
            })
    }
});

export const questionsActions = questionsSlice.actions;

export default questionsSlice.reducer;