import Axios from 'axios';
import appConfig from '../../helpers/config';
import { toast } from "react-toastify";
import { POP_UP_CLOSE_1000, POP_UP_CLOSE_2000, SUCCESS_STATUS, SUCCESS_STATUS_ERROR } from '../../constants/global';
import { API_CUSTOM_MESSAGE } from '../../validations/validationErrors';
let store: any;
let msalIns: any;
const baseUrlApi = appConfig.apiEndPoint;

export const injectStore = (_store: any, msal: any) => {
    store = _store
    msalIns = msal;
}

const DOWNLOADED_URL = ['/case-document-preview/download-bundle-of-documents']

/**
 * Axios Interceptor
 * @param {*} config
 * @returns {*}
 */
function authRequestInterceptor(config: any) {
    const token = store.getState().authSlice.authData;
    const authToken = token === undefined || token === null ? "" : token.jwt_token;
    if (token) {
        if (DOWNLOADED_URL.indexOf(config.url) > -1) {
            config.responseType = 'arraybuffer';
        }
        config.headers.authorization = `Bearer ${authToken}`;
    }
    return config;
}


/**
 * Axios Object to use 
 * @type {*}
 */
export const axiosRequest = Axios.create({
    baseURL: baseUrlApi
});

axiosRequest.interceptors.request.use(authRequestInterceptor);
axiosRequest.interceptors.response.use(
    (response) => {
        if (response.status === SUCCESS_STATUS) {
            const SUCCESS_MESSAGE = response.data.message;
            toast.success(SUCCESS_MESSAGE, { autoClose: POP_UP_CLOSE_1000 });
        } else if (response.status === SUCCESS_STATUS_ERROR) {
            const SUCCESS_MESSAGE = response.data.message
            toast.error(SUCCESS_MESSAGE, { autoClose: POP_UP_CLOSE_1000 });
        }
        return response.data;
    },
    async (error) => {
        console.log("error", error)
        if (error.response.status === 401) {
            // msalIns.logoutRedirect({
            //     postLogoutRedirectUri: "/",
            // });         
            window.localStorage.setItem("checklogout", "forceLogout");
            window.dispatchEvent(new Event("storage"));
        } else {
            let ERROR_MESSAGE = "";
            const errorRsp = error.response;

            const status = error && errorRsp ? errorRsp.status : "";
            if (error && errorRsp && errorRsp.data.msg) {
                ERROR_MESSAGE = errorRsp.data.msg
            }
            if (error && errorRsp && errorRsp.data.message) {
                ERROR_MESSAGE = errorRsp.data.message
            }
            if (status !== SUCCESS_STATUS && ERROR_MESSAGE !== "") {
                toast.error(ERROR_MESSAGE, { autoClose: POP_UP_CLOSE_2000 })
            } else if (status !== SUCCESS_STATUS) {
                toast.error(API_CUSTOM_MESSAGE.SOMETHING_WRONG, { autoClose: POP_UP_CLOSE_1000 });
            }
            else if (status !== SUCCESS_STATUS && ERROR_MESSAGE == "") {
                toast.error(API_CUSTOM_MESSAGE.SOMETHING_WRONG, { autoClose: POP_UP_CLOSE_1000 });
            }


        }
        return Promise.reject(error);
    }
);
