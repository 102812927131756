import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EndPoint from "../../api/Endpoint";
import {
  DEF_SORT_ORDER,
  LIST_CRR_PAGE,
  STAGES_DEFAULT_SORT_COLUMN,
  STAGES_LIST_ROWS_DEF,
} from "../../constants/global";
import { axiosRequest } from "../common-api/axios";

const initialState = {
  emailTemplateData: [],
  loading: false,
  particularDocumentsObj: {},
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STAGES_LIST_ROWS_DEF,
    sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
    filterByTypeProp: "",
    filterByLanguageProp: "",
  },
};

/**
 * Getting Email Template List
 *
 * @type {*}
 */
export const getEmailTemplatesListAction = createAsyncThunk(
  "emailTemplateModule/getEmailTemplatesListAction",
  async (value: any) => {
    const apiUrl =
      value.searchKey === ""
        ? `${EndPoint.KB_EMAIL_TEMPLATES_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&filterByType=${value.filterByType ? value.filterByType : ''}&filterByLanguage=${value.filterByLanguage ? value.filterByLanguage : ''}`
        : `${EndPoint.KB_EMAIL_TEMPLATES_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&filterByType=${value.filterByType ? value.filterByType : '' }&filterByLanguage=${value.filterByLanguage ? value.filterByLanguage : ''}`;
    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Getting All Stages List
 *
 * @type {*}
 */
export const getKnowledgeBaseAllStagesListAction = createAsyncThunk(
  "emailTemplateModule/getKnowledgeBaseAllStagesListAction",
  async () => {
    const apiUrl = EndPoint.KB_EMAIL_TEMPLATES_STAGES_LIST_API;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);
/**
 * Getting All Case Type List
 *
 * @type {*}
 */
export const getKnowledgeBaseAllCaseTypeListAction = createAsyncThunk(
  "emailTemplateModule/getKnowledgeBaseAllCaseTypeListAction",
  async () => {
    const apiUrl = EndPoint.KB_EMAIL_TEMPLATES_CASE_TYPE_LIST_API;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);
/**
 * Getting All document List
 *
 * @type {*}
 */
export const getKnowledgeBaseAllDocumentsListAction = createAsyncThunk(
  "emailTemplateModule/getKnowledgeBaseAllDocumentsListAction",
  async () => {
    const apiUrl = EndPoint.KB_EMAIL_TEMPLATES_DOCUMENTS_LIST_API;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);
/**
 * Adding New Email Template
 *
 * @type {*}
 */
export const addNewEmailTemplateAction = createAsyncThunk(
  "emailTemplateModule/addNewEmailTemplateAction",
  async (value: any) => {
    console.log("dataa", value)
    const addData: any = {
      subject: value.subject.trim(),
      type: value.type,
      language: value.language,
      body: value.body,
      knowledge_base_documents_urls: value.document_list,
      attachments: value.attachment_sample_document_url,

    };

    console.log("addData", addData)
    try {
      const response = await axiosRequest.post(
        `${EndPoint.KB_EMAIL_TEMPLATES_ADD_API}`,
        addData
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 *  Delete Email Template 
 *
 * @type {*}
 */
export const deleteEmailTemplateAction = createAsyncThunk(
  "emailTemplateModule/deleteEmailTemplateAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(
        `${EndPoint.KB_EMAIL_TEMPLATES_DELETE_API}/${value.emailTemplateId}`
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 *  Get Edit Data of Email Template
 *
 * @type {*}
 */
export const getKnowledgeBaseEmailTemplateAction = createAsyncThunk(
  "emailTemplateModule/getKnowledgeBaseEmailTemplateAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(
        `${EndPoint.KB_EMAIL_TEMPLATES_GET_API}/${value}`
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 *  Edit or Update Email Template
 *
 * @type {*}
 */

export const editKnowledgeBaseEmailTemplateAction = createAsyncThunk(
  "emailTemplateModule/editKnowledgeBaseEmailTemplateAction",
  async (value: any) => {
    const editData = {
      email_template_id: value.id,
      subject: value.subject.trim(),
      type: value.type,
      language: value.language,
      body: value.body,
      knowledge_base_documents_urls: value.document_list,
      attachments: value.attachment_sample_document_url,
    };
    try {
      const response = await axiosRequest.put(
        `${EndPoint.KB_EMAIL_TEMPLATES_EDIT_API}`,
        editData
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 *  Status Update Email Template
 *
 * @type {*}
 */

export const KnowledgeBaseEmailTemplateStatusUpdateAction = createAsyncThunk(
  "emailTemplateModule/KnowledgeBaseEmailTemplateStatusUpdateAction",
  async (value: any) => {
   
    try {
      const response = await axiosRequest.put(
        `${EndPoint.KB_EMAIL_TEMPLATES_STATUS_UPDATE_API}`,
        value
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


const knowledgeBaseEmailTemplateSlice: any = createSlice({
  name: "knowledgebase & documents",
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;
      state.listObj.filterByTypeProp = actions.payload.filterByType;
      state.listObj.filterByLanguageProp = actions.payload.filterByLanguage;
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // email template list
      .addCase(getEmailTemplatesListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getEmailTemplatesListAction.fulfilled,
        (state, action) => {
          let data = action.payload.data ? action.payload.data : [];
          state.loading = false;
          state.emailTemplateData = data;
        }
      )
      .addCase(
        getEmailTemplatesListAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )
      // stage list
      .addCase(getKnowledgeBaseAllStagesListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getKnowledgeBaseAllStagesListAction.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(
        getKnowledgeBaseAllStagesListAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )
      // Case type list
      .addCase(getKnowledgeBaseAllCaseTypeListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getKnowledgeBaseAllCaseTypeListAction.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(
        getKnowledgeBaseAllCaseTypeListAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )
      // Document list

      // Add
      .addCase(addNewEmailTemplateAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewEmailTemplateAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewEmailTemplateAction.rejected, (state, action) => {
        state.loading = false;
      })
      // Delete
      .addCase(deleteEmailTemplateAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEmailTemplateAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteEmailTemplateAction.rejected, (state, action) => {
        state.loading = false;
      })
      // Get Edit Data
      .addCase(getKnowledgeBaseEmailTemplateAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKnowledgeBaseEmailTemplateAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data : {};
        state.loading = false;
        state.particularDocumentsObj = data;
      })
      .addCase(getKnowledgeBaseEmailTemplateAction.rejected, (state, action) => {
        state.loading = false;
      })
      // Edit or Update
      .addCase(editKnowledgeBaseEmailTemplateAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editKnowledgeBaseEmailTemplateAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editKnowledgeBaseEmailTemplateAction.rejected, (state, action) => {
        state.loading = false;
      })
      // Status Update on Listing
      .addCase(KnowledgeBaseEmailTemplateStatusUpdateAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(KnowledgeBaseEmailTemplateStatusUpdateAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(KnowledgeBaseEmailTemplateStatusUpdateAction.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const knowledgeBaseEmailTemplateActions =
  knowledgeBaseEmailTemplateSlice.actions;

export default knowledgeBaseEmailTemplateSlice.reducer;
