import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { IoIosArrowDown, IoMdCalendar } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../../assets/images/logo.svg";
import profileImg from "../../../../assets/images/profile-image-dummy.jpg";
import { POP_UP_CLOSE_2000 } from "../../../../constants/global";
import { baseRoutes } from "../../../../routes/base-routes";
import { downloadSharePointFile } from "../../../../service/graph-api/share-point/share-point-file-download";
import { persistor } from "../../../../store";
import HeaderSearch from "./HeaderSearch";
import "./header.scss";
import LogoutHandleModal from "./HandleLogoutModal";
export const Header = ({ toggleSidebarButton, isActiveSidebar }: any) => {
  const { instance, accounts } = useMsal();
  const authDetails = useSelector((state: any) => state.authSlice.authData);
  const [profileImage, setProfileImage] = useState(profileImg);
  const [fullName, setFullName] = useState("");
  const [openLogoutModalGlobalState, setOpenLogoutModalGlobalState] = useState(false);
  useEffect(() => {
    if (authDetails) {
      console.log("From header authDetails", authDetails);
      downloadFile(authDetails);
    }
  }, [authDetails]);
  useEffect(()=>{
    
    // Respond to the `storage` event
    function storageEventHandler(event:any) {
      if(window.localStorage.getItem("checklogout") === 'forceLogout'){
        console.log("storageEventHandler", event);
        setOpenLogoutModalGlobalState(true);
        localStorage.removeItem("checklogout")
      }
    }
    // Hook up the event handler
    window.addEventListener("storage", storageEventHandler);
    return () => {
        // Remove the handler when the component unmounts
        window.removeEventListener("storage", storageEventHandler);
    };
  }, [])
  const downloadFile = (authDetails: any) => {
    setFullName(authDetails.full_name);
    if (authDetails.profile_pic && authDetails.profile_pic !== "") {
      downloadSharePointFile(instance, accounts, authDetails.profile_pic).then((response) => {
        const outObj = response["@microsoft.graph.downloadUrl"];
        if (outObj) {
          const downloadURL = outObj;
          setProfileImage(!downloadURL ? profileImg : downloadURL)
        } else if (response["error"]) {
          const errorObj = response["error"]["code"];
          toast.error(errorObj, { autoClose: POP_UP_CLOSE_2000 });
          setProfileImage(profileImg)
        }
      })
    } else {
      setProfileImage(profileImg)
    }

  }
  const handleCloseModal = () =>{
    setOpenLogoutModalGlobalState(false);
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }
  const handleLogout = async (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      await persistor.purge();
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  const notificationData = [
    {
      title: "New Case Created",
      sub_title: "[EB-2 NIW / CP (11772-001) [KBCAW30216-1] ",
      date_time: "07/21/2023 11:39 AM",
    },
    {
      title: "Case Assigned to",
      sub_title: "John Smith",
      date_time: "07/21/2023 11:35 AM",
    },
    {
      title: "All documents received for",
      sub_title: "All documents received for [EB-2 NIW / CP (11772-001) [KBCAW30216-1]",
      date_time: "07/21/2023 11:35 AM",
    },
    {
      title: "New Case Created",
      sub_title: "[EB-2 NIW / CP (11772-001) [KBCAW30216-1] ",
      date_time: "07/21/2023 11:39 AM",
    },
    {
      title: "Case Assigned to",
      sub_title: "John Smith",
      date_time: "07/21/2023 11:35 AM",
    },
    {
      title: "All documents received for",
      sub_title: "All documents received for [EB-2 NIW / CP (11772-001) [KBCAW30216-1]",
      date_time: "07/21/2023 11:35 AM",
    },
  ]
  return (
    <>
      <Navbar
        bg="white"
        variant="light"
        className="py-0 px-md-1 shadow-sm z-2 theme-top-header"
        data-bs-theme="light"
      >
        <Container fluid className="h-100">
          <Button
            onClick={toggleSidebarButton}
            variant="link"
            className="align-items-center d-flex d-xl-none justify-content-center me-3 navMenuBtn p-0 py-3"
          >
            <span
              className={`bg-primary d-inline-block menuTrigger position-relative text-center ${isActiveSidebar ? "active" : ""
                }`}
            ></span>
          </Button>
          <Link to={baseRoutes.dashboard.path} className="me-3">
            <Image
              className="img-fluid"
              src={Logo}
              alt="Logo"
              width={192}
              height={35}
            />
          </Link>
          <Nav className="ms-auto align-items-center order-md-last">
            <Link
              to={baseRoutes.dashboard.path}
              className="ms-2 me-lg-3 me-1 text-body p-2 d-none d-md-inline-block"
            >
              <IoMdCalendar size={27} />
            </Link>
            <Dropdown className="notificationDropdown me-lg-3 me-1">
              <Dropdown.Toggle
                variant="link"
                id="dropdown-notification"
                className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-body p-2"
              >
                <FaBell size={22} />
                <Badge
                  bg="danger"
                  pill
                  className="align-items-center border border-white customBadge d-flex fs-11 fw-normal justify-content-center mt-1 p-1 position-absolute rounded-pill start-100"
                >
                  {notificationData.length}
                  <span className="visually-hidden">Unread Notifications</span>
                </Badge>
              </Dropdown.Toggle>

              <Dropdown.Menu align="end" className="shadow border-0 mt-3 p-1 theme-notification-menu">
                <ul className="list-unstyled p-1 theme-custom-scrollbar overflow-auto">
                  {notificationData.map((dataItems: any) => {
                    const { title, sub_title, date_time } = dataItems;
                    return (
                      <li key={title} className="fs-15 pb-2 mb-2 border-bottom">
                        <div>{title}</div>
                        <div className="fw-bold">{sub_title}</div>
                        <div className="fs-12 text-body-tertiary">{date_time}</div>
                      </li>
                    )
                  })}
                </ul>
                <Dropdown.Item
                  as={NavLink}
                  to={baseRoutes.notification.path}
                  className="p-1 fs-12 fw-semibold bg-transparent text-center text-light-emphasis"
                  disabled={true}
                >
                  View All
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="profileDropdown ms-1">
              <Dropdown.Toggle
                variant="link"
                id="dropdown-profile"
                className="border-0 fw-semibold text-decoration-none p-0 text-body"
              >
                <Image
                  className="object-fit-cover rounded-circle border border-light-subtle"
                  src={profileImage}
                  alt="Profile Image"
                  width={40}
                  height={40}
                />
                <div className="align-middle fs-6 d-none d-md-inline-block ms-1 px-2 text-truncate max-w-150">
                  {fullName}
                </div>
                <IoIosArrowDown size={16} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end" className="border-0 shadow">
                <Dropdown.Item as={NavLink} to="/app/profile">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to={baseRoutes.reminder.path}
                  disabled={true}
                >
                  Reminder
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLogout("redirect")}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <div className="flex-fill ms-auto max-w-350 d-none d-md-inline-block">
            <HeaderSearch />
          </div>
        </Container>
      </Navbar>
      <LogoutHandleModal 
        show={openLogoutModalGlobalState}
        handleClose={handleCloseModal}          
      />
    </>
  );
};
