import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { LIST_CRR_PAGE, STEPS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    draftEmailData: [],
    loading: false,
    particularClientObj: {},
    listObj: {
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STEPS_LIST_ROWS_DEF,
    },
};

//Get Draft Email List Data
export const getDraftEmailsListAction = createAsyncThunk(
    "composeEmailModule/getDraftEmailsListAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.CLIENT_DRAFT_EMAIL_LIST_API}?page=${value.currentPage}&size=${value.rows}&userPrincipalName=${value.currentUserEmail}&client_id=${value.clientID}&case_number=${value.case_number}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//Delete Email Data
export const deleteDraftEmailsAction = createAsyncThunk(
    "composeEmailModule/deleteDraftEmailsAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.CLIENT_DRAFT_EMAIL_LIST_DELETE_API}?userPrincipalName=${value.currentUserEmail}&email_id=${value.draftEmailId}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//Get Draft Email List Data for Compose
export const getDraftEmailsComposeAction = createAsyncThunk(
    "composeEmailModule/getDraftEmailsComposeAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.CLIENT_DRAFT_EMAIL_COMPOSE_API}?userPrincipalName=${value.currentUserEmail}&email_id=${value.draftEmailId}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// Send Draft Email
export const draftEmailSendAction = createAsyncThunk(
    "composeEmailModule/draftEmailSendAction",
    async (payload: any) => {
        try {
            const response = await axiosRequest.post(
                `${EndPoint.CLIENT_DRAFT_EMAIL_SEND_API}`,
                payload
            );
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// Update Draft Email List
export const updateDraftEmailAction = createAsyncThunk(
    "composeEmailModule/updateDraftEmailAction",
    async (payload: any) => {
        try {
            const response = await axiosRequest.post(
                `${EndPoint.CLIENT_DRAFT_EMAIL_UPDATE_API}`,
                payload
            );
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//Delete Draft Email Attachment
export const deleteAttachmentDraftEmailAction = createAsyncThunk(
    "composeEmailModule/deleteAttachmentDraftEmailAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.CLIENT_DRAFT_EMAIL_ATTACHMENT_DELETE_API}?attachment_id=${value.attachment_id}&userPrincipalName=${value.currentUserEmail}&email_id=${value.draftEmailId}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// Update Draft Email List
export const updateDraftEmailAttachmentAction = createAsyncThunk(
    "composeEmailModule/updateDraftEmailAttachmentAction",
    async (payload: any) => {
        try {
            const response = await axiosRequest.post(
                `${EndPoint.CLIENT_DRAFT_EMAIL_ATTACHMENT_UPDATE_API}`,
                payload
            );
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const draftEmailSlice = createSlice({
    name: 'draft email',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            state.listObj.currentPageProp = actions.payload.currentPage;
            state.listObj.rowsProp = actions.payload.rows;
        },
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Listing of Draft Email
            .addCase(getDraftEmailsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDraftEmailsListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getDraftEmailsListAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Delete Draft Email
            .addCase(deleteDraftEmailsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteDraftEmailsAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteDraftEmailsAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Get Draft Email Single Data
            .addCase(getDraftEmailsComposeAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDraftEmailsComposeAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getDraftEmailsComposeAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Draft Email Send
            .addCase(draftEmailSendAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(draftEmailSendAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(draftEmailSendAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Update Draft Email
            .addCase(updateDraftEmailAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateDraftEmailAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateDraftEmailAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Delete Attachment Draft Email
            .addCase(deleteAttachmentDraftEmailAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAttachmentDraftEmailAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteAttachmentDraftEmailAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Update Draft Email Attachment Action
            .addCase(updateDraftEmailAttachmentAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateDraftEmailAttachmentAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateDraftEmailAttachmentAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});


export const draftEmailModuleActions = draftEmailSlice.actions;

export default draftEmailSlice.reducer;