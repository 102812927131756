import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, TEAMS_DEFAULT_SORT_COLUMN, TEAMS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';


const initialState = {
    teamsData: [],
    loading: false,
    designationList: [],
    teamsDropDownsData: [],
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: TEAMS_LIST_ROWS_DEF,
        sortFieldProp: TEAMS_DEFAULT_SORT_COLUMN
    }

};


export const getTeamsListAction = createAsyncThunk(
    "teamsModule/getTeamsListAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ? `${EndPoint.TEAMS_LIST_API}page=${value.currentPage}&size=${value.rows}&sort_order=${value.sortOrder}` :
            `${EndPoint.TEAMS_LIST_API}page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort_order=${value.sortOrder}`
        try {

            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {

            return error;

        }
    }
);

// GETTING TEAM DETAILS BASSED ON ID
export const getTeamsByIDAction = createAsyncThunk(
    "knowledgebaseModule/getTeamsByIDAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.get(`teams/detail/${value.teamId}`);
            return response;

        } catch (error: any) {

            return error;

        }
    }
);


export const getTeamsDropdownsDataAction = createAsyncThunk(
    "teamsModule/getTeamsDropdownsDataAction",
    async () => {

        try {

            const response = await axiosRequest.get(EndPoint.TEAMS_DROPDOWNS_DATA_API);

            return response;

        } catch (error: any) {

            return error;

        }
    }
);


export const addNewTeamAction = createAsyncThunk(
    "teamsModule/addNewTeamAction",
    async (value: any) => {


        const addData = {
            team_name: value.teamName,
            logo_url: value.logoImage,
            team_member_details: value.teamMemberDetail

        }
        try {

            const response = await axiosRequest.post(`${EndPoint.TEAMS_ADD_API}`, addData);
            return response;

        } catch (error: any) {

            return error;

        }
    }
);



export const updateTeamsTeamAction = createAsyncThunk(
    "teamsModule/updateTeamsTeamAction",
    async (value: any) => {


        const editData = {
            team_name: value.teamName,
            logo_url: value.logoImage,
            team_member_details: value.teamMemberDetail,
            team_id: value.teamId

        }
        try {

            const response = await axiosRequest.put(`${EndPoint.TEAMS_UPDATE_API}`, editData);
            return response;

        } catch (error: any) {

            return error;

        }
    }
);


export const exportTeamAction = createAsyncThunk(
    "teamsModule/exportTeamAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ? `${EndPoint.TEAMS_EXPORT_API}?sort=${value.sortField}&sort_order=${value.sortOrder}` :
        `${EndPoint.TEAMS_EXPORT_API}?sort=${value.sortField}&search=${value.searchKey}&sort_order=${value.sortOrder}`
        try {

            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {

            return error;

        }
    }
);

export const getDesignationList = createAsyncThunk(
    "teamsModule/getDesignationList",
    async (value:any, thunkAPI) => {
        try {
          const response = await axiosRequest.get(`${EndPoint.DESIGNATION_LIST}`);  
          return response;
        } catch (error:any) {
            return error;
        }
    }
);

const teamSlice = createSlice({
    name: 'teams',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            state.listObj.searchKeyProp = actions.payload.searchKey;
            state.listObj.sortOrderProp = actions.payload.sortOrder;
            state.listObj.currentPageProp = actions.payload.currentPage;
            state.listObj.rowsProp = actions.payload.rows;
            state.listObj.sortFieldProp = actions.payload.sortField;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeamsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTeamsListAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : [];
                state.loading = false;
                state.teamsData = data
            })

            .addCase(getTeamsListAction.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(getTeamsDropdownsDataAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTeamsDropdownsDataAction.fulfilled, (state, action) => {

                let data = action.payload.data ? action.payload.data : [];
                state.loading = false;
                state.teamsDropDownsData = data;
            })

            .addCase(getTeamsDropdownsDataAction.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(addNewTeamAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewTeamAction.fulfilled, (state, action) => {

                state.loading = false;
            })

            .addCase(addNewTeamAction.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(updateTeamsTeamAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateTeamsTeamAction.fulfilled, (state, action) => {
                state.loading = true;
            })

            .addCase(updateTeamsTeamAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(exportTeamAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(exportTeamAction.fulfilled, (state, action) => {

                state.loading = false;
            })

            .addCase(exportTeamAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getDesignationList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDesignationList.fulfilled, (state, action) => {
               const designation = action.payload.data ? action.payload.data : [];
                state.loading = false;
                state.designationList = designation;

            })
            .addCase(getDesignationList.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getTeamsByIDAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTeamsByIDAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getTeamsByIDAction.rejected, (state, action) => {
                state.loading = false;
            })

    }
});


export const teamsActions = teamSlice.actions;

export default teamSlice.reducer;