import { SearchSchema } from '../../../../validations/search';
import CommonFormikComponent from '../../CommonFormikComponent';
import ThemeInputSearch from '../../InputSearch';

const HeaderSearch = () => {
    // Initial Values
    const initialValues: Record<string, any> = {
        search: ''
    };

    // Handle Submit
    const handleSubmit = (values: Record<string, any>, actions: any) => {
        actions.setSubmitting(false);
    };

    return (
        <CommonFormikComponent
            validationSchema={SearchSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {(formikProps) => (
                <>
                    <ThemeInputSearch
                        inputWrapClass="p-inputgroup"
                        inputClassName="bg-body-secondary border-light-subtle"
                        buttonClassName="border-light-subtle"
                        controlId="search"
                        placeholder='Search'
                        handleBlur={formikProps.handleBlur}
                        handleChange={formikProps.handleChange}
                        value={formikProps.values.search}
                        touched={formikProps.touched.search}
                        errorsField={formikProps.errors.search}
                    />
                </>
            )}
        </CommonFormikComponent>
    )
}

export default HeaderSearch