import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EndPoint from "../../api/Endpoint";
import {
  DEF_SORT_ORDER,
  LIST_CRR_PAGE,
  STAGES_DEFAULT_SORT_COLUMN,
  STAGES_LIST_ROWS_DEF,
} from "../../constants/global";
import { axiosRequest } from "../common-api/axios";
import axios from "axios";
import appConfig from "../../helpers/config";

const initialState = {
  inboxEmailData: [],
  sendEmailData: [],
  loading: false,
  particularEmailssObj: {},
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STAGES_LIST_ROWS_DEF,
    sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
    filterByTypeProp: "",
    filterByLanguageProp: "",
  },
  pageCountObj: {
    inboxPageCount: 1,
    sendPageCount: 1,
  },
  replyReplyAll:{
    type:'',
    emailObj:{}
  },
  activeInboxOrSentScreen: 'inboxEmails'
};

/**
 * Getting All Email inbox List data
 *
 * @type {*}
 */
export const getEmailInboxListAction = createAsyncThunk(
  "composeEmailModule/getEmailInboxListAction",
  async (value: any) => {
    const apiUrl = `${EndPoint.EMAIL_INBOX_API}/${value.clientId}?page=1&size=${value.page*20}&sort_order=desc&search=${value.search ? value.search : '' }&case_number=${value.case ? value.case : ''}`;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Getting All Email Send List data
 *
 * @type {*}
 */
export const getEmailSendListAction = createAsyncThunk(
  "composeEmailModule/getEmailSendListAction",
  async (value: any) => {
    const apiUrl = `${EndPoint.EMAIL_SEND_API}/${value.clientId}?page=1&size=${value.page*20}&sort_order=desc&search=${value.search ? value.search : '' }&case_number=${value.case ? value.case : ''}`;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Mark Read Unread Email Send List data
 *
 * @type {*}
 */
export const getReadUnreadAction = createAsyncThunk(
  "composeEmailModule/getReadUnreadAction",
  async (value :any) => {
    const apiUrl = `${EndPoint.EMAIL_STATUS_API}/${value?.id}/${value?.isRead}`;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Mark Read Unread Email Send List data
 *
 * @type {*}
 */
export const getAttachmentsAction = createAsyncThunk(
  "composeEmailModule/getAttachmentsAction",
  async (value :any) => {
    const apiUrl = `${EndPoint.EMAIL_ATTACHMENTS_API}?userPrincipalName=${value.userPrincipalName}&email_id=${value.email_id}`;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Get mime data
 *
 * @type {*}
 */
export const getClientEmailMime = createAsyncThunk(
  "composeEmailModule/getClientEmailMime",
  async (value :any) => {
    const apiUrl = `${EndPoint.EMAIL_MIME_API}?userPrincipalName=${value.userPrincipalName}&email_id=${value.email_id}`;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Case List 
 *
 * @type {*}
 */
export const getClientCasesListAction = createAsyncThunk(
  "composeEmailModule/getClientCasesListAction",
  async (clientId: any) => {
    const apiUrl = `${EndPoint.CLIENT_CASES_LIST_API}?client_id=${clientId}`
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Add compose New Reply/ReplyAll
 *
 * @type {*}
 */
export const addNewComposeReplyReplyAllEmailAction = createAsyncThunk(
  "composeEmailModule/addNewComposeReplyReplyAllEmailAction",
  async (payload: any) => {
    try {
      const response = await axiosRequest.post(
        `${EndPoint.EMAIL_REPLY_REPLY_ALL_API}`,
        payload
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Add compose forward email
 *
 * @type {*}
 */
export const addNewComposeForwardEmailAction = createAsyncThunk(
  "composeEmailModule/addNewComposeForwardEmailAction",
  async (payload: any) => {
    try {
      const response = await axiosRequest.post(
        `${EndPoint.EMAIL_FORWARD_API}`,
        payload
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


const emailsSlice: any = createSlice({
  name: "client managment & communication & compose email",
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;
      state.listObj.filterByTypeProp = actions.payload.filterByType;
      state.listObj.filterByLanguageProp = actions.payload.filterByLanguage;
     
    },
    changePageCountObj(state, actions) {
      state.pageCountObj.inboxPageCount = actions.payload.inboxPageCount;
      state.pageCountObj.sendPageCount = actions.payload.sendPageCount;
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
    ChangeReplyReplyAll(state, actions) {
      state.replyReplyAll.type = actions.payload.type;
      state.replyReplyAll.emailObj = actions.payload.emailObj;
    },
    getActiveInboxOrSentScreen(state, actions) {
      state.activeInboxOrSentScreen = actions.payload.activeInboxOrSentScreen;
    },
  },
  extraReducers: (builder) => {
    builder
      // Listing of Email Inbox
      .addCase(getEmailInboxListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmailInboxListAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data : [];
        state.loading = false;
        state.inboxEmailData = data;
      })
      .addCase(getEmailInboxListAction.rejected, (state, action) => {
        state.loading = false;
      })

      // Listing of Email Send
      .addCase(getEmailSendListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmailSendListAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data : [];
        state.loading = false;
        state.sendEmailData = data;
      })
      .addCase(getEmailSendListAction.rejected, (state, action) => {
        state.loading = false;
      })

       // Listing of Email Read and Unread
      .addCase(getReadUnreadAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReadUnreadAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getReadUnreadAction.rejected, (state, action) => {
        state.loading = false;
      })

      // Attachment of Email
      .addCase(getAttachmentsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAttachmentsAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getAttachmentsAction.rejected, (state, action) => {
        state.loading = false;
      })

      // Mime type for Documents or attachments or images
      .addCase(getClientEmailMime.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientEmailMime.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getClientEmailMime.rejected, (state, action) => {
        state.loading = false;
      })

       // Case Number
      .addCase(getClientCasesListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientCasesListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getClientCasesListAction.rejected, (state, action) => {
        state.loading = false;
      })
      
      // Reply/Reply All
      .addCase(addNewComposeReplyReplyAllEmailAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewComposeReplyReplyAllEmailAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewComposeReplyReplyAllEmailAction.rejected, (state, action) => {
        state.loading = false;
      })
      // Forward email
      .addCase(addNewComposeForwardEmailAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewComposeForwardEmailAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewComposeForwardEmailAction.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const emailsModuleActions = emailsSlice.actions;

export default emailsSlice.reducer;
