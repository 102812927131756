import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { STAGES_LIST_ROWS_DEF, LIST_CRR_PAGE, DEF_SORT_ORDER, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    knowledgeBaseDocChecklistItemData: [],
    loading:false,
    particularDocChecklistItemObj:{},
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN
    }
};

export const getKnowledgeBaseDocChecklistItemsListAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/getKnowledgeBaseDocChecklistItemsListAction",
    async (value:any) => {
        const apiUrl = value.searchKey === "" ? 
        `${EndPoint.KB_DOC_CHK_ITEMS_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}` : 
        `${EndPoint.KB_DOC_CHK_ITEMS_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}`;
        try {
            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

export const getKnowledgeBaseDocTypesListAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/getKnowledgeBaseDocTypesListAction",
    async () => {
        const apiUrl = EndPoint.KB_DOC_TYPE_LIST_API;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

export const addNewDocCheckListItemAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/addNewDocCheckListItemAction",
    async (value:any) => {
        const addData:any = {
            title : value.title.trim(),
            type: value.type === ''? null : value.type,
            upload_to_folder: value.upload_to_folder,
            sample_document_url: value.sample_document_url
        }
        try {
            const response = await axiosRequest.post(`${EndPoint.KB_DOC_CHK_ITEMS_ADD_API}`, addData);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);

export const getDocCheckListItemAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/getDocCheckListItemAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.KB_DOC_CHK_ITEMS_GET_API}/${value}`);
          
            return response;
        } catch (error:any) {

            return error;
        }
    }
);

export const editNewDocCheckListItemAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/editNewDocCheckListItemAction",
    async (value:any) => {
        const addData:any = {
            id:value.id,
            title : value.title.trim(),
            type: value.type === ''? 0 : value.type,
            upload_to_folder: value.upload_to_folder,
            sample_document_url: value.sample_document_url
        }
        try {
            const response = await axiosRequest.put(`${EndPoint.KB_DOC_CHK_ITEMS_EDIT_API}`, addData);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);

export const deleteDocCheckListItemAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/deleteDocCheckListItemAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.KB_DOC_CHK_ITEMS_DELETE_API}/${value.itemId}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);

const knowledgeBaseDocChkItemsSlice:any = createSlice({
    name: 'knowledgebase & stage',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
        },
        startLoader(state,actions) {
            state.loading = true;
        },
        stopLoader(state,actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getKnowledgeBaseDocChecklistItemsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseDocChecklistItemsListAction.fulfilled, (state, action) => {
                
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.knowledgeBaseDocChecklistItemData = data;
            })
            .addCase(getKnowledgeBaseDocChecklistItemsListAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getKnowledgeBaseDocTypesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseDocTypesListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getKnowledgeBaseDocTypesListAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(addNewDocCheckListItemAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewDocCheckListItemAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addNewDocCheckListItemAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getDocCheckListItemAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDocCheckListItemAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getDocCheckListItemAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(editNewDocCheckListItemAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(editNewDocCheckListItemAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(editNewDocCheckListItemAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(deleteDocCheckListItemAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteDocCheckListItemAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteDocCheckListItemAction.rejected, (state, action) => {
               state.loading = false;
            })
            
    }
});

export const knowledgeBaseDocChkItemsActions = knowledgeBaseDocChkItemsSlice.actions;

export default knowledgeBaseDocChkItemsSlice.reducer;