import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { axiosRequest } from '../common-api/axios';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STAGES_LIST_ROWS_DEF, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
const initialState = {
    questionnaire: [],
    loading: false,
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN
    }
};


/**
 * Getting Questionnaire List
 *
 * @type {*}
 */
export const getQuestionnairesListAction = createAsyncThunk(
    "questionnairesModule/getQuestionnairesListAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ?
            `${EndPoint.QUESTIONAIRRE_LIST}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}` :
            `${EndPoint.QUESTIONAIRRE_LIST}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}`;

        try {
            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {
            return error;

        }
    }
);

/**
 * Adding Questionnaire 
 *
 * @type {*}
 */
export const addQuestionnaire = createAsyncThunk(
    "questionnairesModule/addQuestionnaire",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.values
        try {
            const response = await axiosRequest.post(`${EndPoint.QUESTIONAIRRE_ADD}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Adding Questionnaire
 *
 * @type {*}
 */
export const updateQuestionnaire = createAsyncThunk(
    "questionnairesModule/addQueupdateQuestionairrestionairre",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.values
        try {
            const response = await axiosRequest.put(`${EndPoint.QUESTIONAIRRE_UPDATE}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Getting Questionnaire By ID
 *
 * @type {*}
 */
export const getQuestionnaireById = createAsyncThunk(
    "questionnairesModule/getQuestionnaireById",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.QUESTIONAIRRE_BY_ID}/${value.questionairreID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


/**
 * Deletion Of Questionnaire
 *
 * @type {*}
 */
export const deleteQuestionnaireAction = createAsyncThunk(
    "questionnairesModule/deleteQuestionnaireAction",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.delete(`${EndPoint.QUESTIONAIRRE_DELETE}/${value.questionnaireId}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 * Export Questionnaire Action
 *
 * @type {*}
 */
export const exportQuestionnaireAction = createAsyncThunk(
    "questionnairesModule/exportQuestionnaireAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ? `${EndPoint.QUESTIONAIRRE_EXPORT}?sort=${value.sortField}&sort_order=${value.sortOrder}` :
        `${EndPoint.QUESTIONAIRRE_EXPORT}?sort=${value.sortField}&search=${value.searchKey}&sort_order=${value.sortOrder}`
        try {

            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {

            return error;

        }
    }
);

/**
 * Getting ALL Common Questionnaires Action
 *
 * @type {*}
 */
export const getAllQuestionnaireListCommonItemAction = createAsyncThunk(
    "knowledgebasedocchkModule/getAllQuestionnaireListCommonItemAction",
    async () => {
        try {
            const response = await axiosRequest.get(EndPoint.QUESTIONAIRRE_COMMON_LIST);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
)

const questionairreSlice = createSlice({
    name: 'Questoinairre Management',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionnairesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getQuestionnairesListAction.fulfilled, (state, action) => {
                state.loading = false;
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.questionnaire = data;
            })
            .addCase(getQuestionnairesListAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addQuestionnaire.pending, (state) => {
                state.loading = true;
            })
            .addCase(addQuestionnaire.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addQuestionnaire.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getQuestionnaireById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getQuestionnaireById.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getQuestionnaireById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateQuestionnaire.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateQuestionnaire.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateQuestionnaire.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteQuestionnaireAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteQuestionnaireAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteQuestionnaireAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(exportQuestionnaireAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(exportQuestionnaireAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(exportQuestionnaireAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const questionairreActions = questionairreSlice.actions;

export default questionairreSlice.reducer;