import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EndPoint from "../../api/Endpoint";
import {
  DEF_SORT_ORDER,
  LIST_CRR_PAGE,
  STAGES_DEFAULT_SORT_COLUMN,
  STAGES_LIST_ROWS_DEF,
} from "../../constants/global";
import { axiosRequest } from "../common-api/axios";
import axios from "axios";
import appConfig from "../../helpers/config";

// let store:any;
// let msalIns:any;
// export const injectStore = (_store:any, msal:any) => {
//   store = _store
//   msalIns = msal;
// }
const baseUrlApi = appConfig.apiEndPoint;

const initialState = {
  composeEmailData: [],
  loading: false,
  particularDocumentsObj: {},
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STAGES_LIST_ROWS_DEF,
    sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
    filterByTypeProp: "",
    filterByLanguageProp: "",
  },
};

// const token = store.getState().authSlice.authData;
// const authToken = token === undefined || token === null ? "": token.jwt_token;


/**
 * Getting All Email Template List
 *
 * @type {*}
 */
export const getEmailTemplateListAction = createAsyncThunk(
  "composeEmailModule/getEmailTemplateListAction",
  async () => {
    const apiUrl = EndPoint.COMPOSE_EMAIL_EMAIL_TEMPLATE_LIST_API;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Getting All document List
 *
 * @type {*}
 */
export const getEmailTemplateKBAllDocumentsListAction = createAsyncThunk(
  "composeEmailModule/getKnowledgeBaseAllDocumentsListAction",
  async () => {
    const apiUrl = EndPoint.KB_EMAIL_TEMPLATES_DOCUMENTS_LIST_API;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Getting Forms List
 *
 * @type {*}
 */
export const getFormsListAction = createAsyncThunk(
  "composeEmailModule/getFormsListAction",
  async () => {
    const apiUrl = EndPoint.KB_FORMS_GET_ALL_COMMON;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Add compose New Email
 *
 * @type {*}
 */
export const addNewComposeEmailAction = createAsyncThunk(
  "composeEmailModule/addNewComposeEmailAction",
  async (payload: any) => {
    try {
      const response = await axiosRequest.post(
        `${EndPoint.COMPOSE_EMAIL_EMAIL_ADD_API}`,
        payload
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);



/**
 *  compose Recepients data
 *
 * @type {*}
 */
export const getChooseReceipientsAction = createAsyncThunk(
  "composeEmailModule/getChooseReceipientsAction",
  async () => {
    try {
      const response = await axiosRequest.get(
        `${EndPoint.COMPOSE_EMAIL_CHOOSE_RECEIPIENTS_API}`
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Save Draft of Compose Email
 *
 * @type {*}
 */
export const saveDraftForComposeEmailAction = createAsyncThunk(
  "composeEmailModule/saveDraftForComposeEmailAction",
  async (payload: any) => {
    try {
      const response = await axiosRequest.post(
        `${EndPoint.COMPOSE_EMAIL_SAVE_DRAFT_API}`,
        payload
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

const composeEmailSlice: any = createSlice({
  name: "client managment & communication & compose email",
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;
      state.listObj.filterByTypeProp = actions.payload.filterByType;
      state.listObj.filterByLanguageProp = actions.payload.filterByLanguage;
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Listing of Email Template
      .addCase(getEmailTemplateListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmailTemplateListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getEmailTemplateListAction.rejected, (state, action) => {
        state.loading = false;
      })

      // Listing of Documents
      .addCase(getEmailTemplateKBAllDocumentsListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getEmailTemplateKBAllDocumentsListAction.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(
        getEmailTemplateKBAllDocumentsListAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      // Listing of Forms
      .addCase(getFormsListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormsListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getFormsListAction.rejected, (state, action) => {
        state.loading = false;
      })

      // Add
      .addCase(addNewComposeEmailAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewComposeEmailAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewComposeEmailAction.rejected, (state, action) => {
        state.loading = false;
      })

      // Listing of Choose Receipients
      .addCase(getChooseReceipientsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChooseReceipientsAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getChooseReceipientsAction.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const composeEmailModuleActions = composeEmailSlice.actions;

export default composeEmailSlice.reducer;
