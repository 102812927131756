import { FC } from "react";
import Form from "react-bootstrap/Form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IoIosSearch } from "react-icons/io";
import { IO_IOS_SEARCH_SIZE } from "../../constants/global";
interface IThemeInputSearch {
  controlId: string;
  label?: string;
  placeholder?: string;
  handleChange: any;
  handleBlur: any;
  errorsField: any;
  touched: any;
  value: any;
  inputClassName?: any;
  inputWrapClass?: any;
  buttonClassName?: any;
}

/**
 * Input Search Reusable Component
 * @date 9/5/2023 - 1:29:52 PM
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; inputClassName: any; inputWrapClass: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, inputClassName, inputWrapClass,
}
 * @returns {*}
 */

const ThemeInputSearch: FC<IThemeInputSearch> = ({
  controlId,
  label,
  placeholder,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  value,
  inputClassName,
  inputWrapClass,
  buttonClassName,
}) => {
  return (
    <Form.Group controlId={controlId}>
      {label ? (
        <Form.Label
          className={`mb-1 d-flex ${
            inputClassName === "theme-inputtext-lg" ? "" : "small"
          }`}
        >
          {label}
        </Form.Label>
      ) : (
        ""
      )}
      <div className={`position-relative w-100 ${inputWrapClass || ""}`}>
        <InputText
          id={controlId}
          placeholder={placeholder}
          value={value}
          onChange={handleChange(controlId)}
          className={
            errorsField && touched
              ? `p-invalid is-invalid w-100 ${inputClassName || ""}`
              : `w-100 ${inputClassName || ""}`
          }
          onBlur={handleBlur(controlId)}
        />
        <Button
          type="submit"
          icon={<IoIosSearch size={IO_IOS_SEARCH_SIZE} />}
          className={`bg-body-secondary w-auto text-body-secondary border py-1 border-start-0 ${
            buttonClassName || "border-dark-subtle"
          }`}
          style={{ marginLeft: "-1px" }}
        />
      </div>
      <Form.Control.Feedback
        type="invalid"
        className={errorsField && touched ? "d-block" : "d-none"}
      >
        {errorsField}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default ThemeInputSearch;
