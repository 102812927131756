import { RouteObject, useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
/**
 *
 * @returns Routes Elements
 */

export default function AppRoutes() {
  const mainRoutes: RouteObject[] = protectedRoutes;
  const element = useRoutes([...mainRoutes]);
  const elementPublic = useRoutes([...publicRoutes]);
  return (
    <>
      <AuthenticatedTemplate>{element}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>{elementPublic}</UnauthenticatedTemplate>
    </>
  );
}
